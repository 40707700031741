import { Injectable } from '@angular/core';
import { first, Observable, tap } from "rxjs";
import { JwtTokenObject } from "../../api/models/auth/jwt-token-object";
import { LoggerService } from "../../logger/logger.service";
import { ApiClientIpaasService } from "../../api/api-client-ipaas.service";
import prismatic from "@prismatic-io/embedded";

@Injectable({
    providedIn: 'root'
})
export class IntegrationIpaasService {
    private readonly loggerName: string = 'IntegrationIpaasService';

    constructor(
        private logger: LoggerService,
        private apiClientIpaasService: ApiClientIpaasService
    ) {
    }

    private tokenObj: JwtTokenObject;

    init(): void {
        this.logger.debug(this.loggerName, 'init()');
        prismatic.init({
            fontConfiguration: {
                google: {
                    families: ["Poppins"],
                },
            },
        });
    }

    generateIpaasToken(): Observable<JwtTokenObject> {
        return this.apiClientIpaasService.generateIpaasToken().pipe(
            first(),
            tap(res => this.tokenObj = res),
            tap(() => this.logger.debug(this.loggerName, 'Generated IPAAS token'))
        );
    }

    authenticate(): void {
        prismatic.authenticate({token: this.tokenObj.authToken});
    }

    configureIntegration(integrationId: string): void {
        prismatic.configureInstance({
            integrationId,
            skipRedirectOnRemove: true,
            usePopover: true,
            screenConfiguration: {
                isInPopover: true,
                configurationWizard: {
                    isInModal: true,
                },
                instance: {
                    hideBackToMarketplace: true,
                },
            }
        });
    }

}
