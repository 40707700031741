export const environment = {
	production: true,
	tenant: {
		eu: 'https://portal.saymine.com/',
		us: 'https://us.portal.saymine.com/'
	},
	about: {
		name: 'saymine.com',
		url: 'https://www.saymine.com/'
	},
	api: {
		accessPointUrl: 'https://api.portal.saymine.com/api/',
		discoveryPendingInterval: 3000,
		discoveryReadyInterval: 30000,
		maxServices: 2000,
		errorHandling: {
			maxNumOfRetries: 3
		}
	},
	authentication: {
		ssoClientId: 'IYUc1hCH4UflvkWDqQqHu3bhtySXOrYh',
		ssoDomain: 'b2b-test.eu.auth0.com',
		jwtTokenObject: 'jwtToken',
		tokenLocalStorageKey: 'authToken',
		tokenIssuerString: 'webApi',
		validateJwtIssuer: true,
		redirectUrl: {
			google: 'https://portal.saymine.com/gmailCallback/',
			microsoft: 'https://portal.saymine.com/microsoftCallback/',
			auth0: 'https://portal.saymine.com/auth0Callback/',
			sso: 'https://portal.saymine.com/ssoCallback/',
		}
	},
	cms: {
		backupEndpoint: 'https://storage.googleapis.com/mine-cms-backup/portal'
	},
	dataMappingAuth: {
		redirectUrl: {
			google: {
				selfAdd: 'https://portal.saymine.com/discovery/gmailCallback/', // connect google test account
				external: 'https://portal.saymine.com/data-mapping-login/connect-user/gmailCallback/', // connect google external employee (after invitation)
			},
			microsoft: {
				selfAdd: 'https://portal.saymine.com/discovery/microsoftCallback/', // connect microsoft test account
				external: 'https://portal.saymine.com/data-mapping-login/connect-user/microsoftCallback/', // connect microsoft external user (after invitation)
			}
		},
	},
	assets: {
		url: 'https://static.saymine.com/app'
	},
	analytics: {
		propertyId: 'UA-37841362-6',
		mixpanelId: '7d2ca725089971064c8eaacd6678c51e'
	},
	intercom : {
		appId: 'lxjtuxh8'
	},
	hubspot: {
		enableChat: false
	},
	logger: {
		console : {
			level : 1000,
		},
		ajax : {
			project : 'Portal',
			// See: http://js.jsnlog.com/Documentation/HowTo/NumericSeverities
			level : 4000,
			url : 'https://api.saymine.com/api/logger'
		}
	},
	routing: {
		redirect: 'redirectUrl',
		permissionsDeclinedUrl: 'https://portal.saymine.com/login',
		pendingVerificationUrl: 'https://portal.saymine.com/pending-verification',
		privacyPolicy: 'https://saymine.com/portal-privacy-policy',
		termsOfService: 'https://saymine.com/portal-terms-of-service',
		cookiesDisabled: 'https://saymine.com/cookies-disabled',
		mineForBusiness: 'https://saymine.com/business',
		externalDataMappingLogin: 'https://portal.saymine.com/data-mapping-login/connect-user',
		yourstruly: '',
		helpusimprove: ''
	},
	dataActions: {
		historyCount: '20',
		skip: '0'
	},
	layout: {
		tabletWidth: 1200,
		mobileWidth: 600
	},
	notifications: {
		snackbarDisplayTime: 5000
	},
	postCheckout: {
		pollingInterval: 2000,
		pollingAttemptsThreshold: 15,
		recentModificationTimeFrame: 300_000,
		timeToWaitBeforeRedirect: 4000,
		successfulUrl: 'https://portal.saymine.com',
	},
	privacyCenter: {
		domain: 'https://privacy.saymine.io',
	},
	inviteMembers: {
		freeTierInviteesThreshold: 2
	},
	pusher: {
		app_id: "1770812",
		auth_endpoint: "PusherAuth/Authorize",
		ds_event_type: "ds-message",
		fe_event_type: "client-message",
		key: "abb7372fbb4885319399",
		secret: "db68fd7b19339198aafd",
		cluster: "eu",
	},
	pspdfKit: {
		licenseKey: "iVwD5f5yU4CDf3GxYxrk4YgwP_Ko_Tulz38ZnjacUFzqZVUHbn-RYHSOomDP3RkzC8cua8CAC4GT7ACXktvh81EIth5rNy6VHc2Wv7iXOjw9HEawVvPKxTY4Dov-8Esb89Us-G41XHiXWuwtd6EjQHkYcRq5FEpmU1SE74em_Y3dD0oUnta2tPmLQLtd7NaWQaRNYC2oSCnHtwc6dtQxD8dyF_3xX_FxA-1lcSyvDo3OuF51qUszd3userD3PzECtVDP9YvTXytahSajgGwVUTVLXMFTBMFVbmhv00l5-gPXwYOp-A7iRh4hmzU2QXLdv5KaF1FPUHGSj3AgA2AmzKkUpegFJ3pGK6YzUmd_fzjDCK9QYZN6rpomXd-ohh8OnnQPt0CHRY1ic-VDHaLcGFhlOBmwWmn80nCxdLHBR55bOB_pMTYb64htYrb7NXFU-W9E5S1NGztxP3iW-QunUX9oGqDFPQZ7JDlZN6JW5tos89fpyxM91GJS1W25VDE5f-qZlSECZl2u6sOkyvUHL9_ziixElq_6XoF5VBkBWsJFFdWX_fSKQtFRXGWto2NZ"
	}
};