import { Injectable } from "@angular/core";
import { EMPTY, Observable, catchError, finalize, first, map, tap } from "rxjs";
import { DialogRef } from '@ngneat/dialog';

import { LoggerService } from "../logger/logger.service";
import { HttpClientHelperService } from "../api/http-client-helper.service";
import { MineAsyncOperationSpinnerDialogComponent } from "../shared/mine-async-operation-spinner-dialog/mine-async-operation-spinner-dialog";
import { DatePipe } from "@angular/common";
import { MineSnackbarService } from "../shared/mine-snackbar/mine-snackbar.service";
import { MineSnackbarType } from "../shared/mine-snackbar/mine-snackbar-type";
import { ContentPipe } from "../services/content/content.pipe";
import { DialogsManagerService } from "../services/dialogs-manager.service";
import { PdfConversionRequest } from "./pdf-generator.interface";

@Injectable({
	providedIn: 'root'
})
export class PdfGeneratorService {
    private readonly loggerName = 'PdfGeneratorService';

    private dialogRef: DialogRef<MineAsyncOperationSpinnerDialogComponent>;

    constructor(
        private loggerService: LoggerService,
        private snackbarService: MineSnackbarService,
        private httpClientHelper: HttpClientHelperService,
        private dialogService: DialogsManagerService,
        private datePipe: DatePipe,
        private contentPipe: ContentPipe
    ) {}

    sendRequest(requestData: PdfConversionRequest): Observable<ArrayBuffer> {
        this.loggerService.info(this.loggerName, 'Starting to create assessments PDF');
        this.dialogRef = this.dialogService.openDialogCommon(MineAsyncOperationSpinnerDialogComponent, this.contentPipe.transform('pdf-generator.generatingPdfReportTitle'), '442px', false);

        const payload = {
            ...requestData,
            data: JSON.stringify(requestData.data),
        };
        return this.httpClientHelper.invokePostPdfAuth<ArrayBuffer>('reports/v1/pdfexport', payload).pipe(
            first(),
            tap(() => this.loggerService.info(this.loggerName, 'PDF downloaded successfully')),
            tap(() =>  this.snackbarService.showTimed(MineSnackbarType.Confirmation, this.contentPipe.transform('pdf-generator.exportFinished'))),
            catchError(error => {
                this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('pdf-generator.exportError'));
                this.loggerService.error(this.loggerName, 'An error ocurred. Could not download assessments PDF.');
                console.error(error);
                return EMPTY;
            }),
            finalize(() => this.dialogRef?.close()),
        );
    }

    public triggerDownload(buffer: ArrayBuffer, desiredFileName?: string): void {
        const link = document.createElement('a');
        const blob = new Blob([buffer], { type: 'application/pdf' });
        const fileName = desiredFileName ?? `Ropa Report - ${this.datePipe.transform(new Date(), 'dd MMM yyyy')}`

        link.href = window.URL.createObjectURL(blob);
        link.download = fileName + '.pdf';
        link.click();
    }
}